<template>
  <div id="user-profile">
    <user-header />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <prayer-nav />
      </div>
    </b-card>
    <b-card
      no-body
      class="mb-0 mt-2"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-table-simple
              hover
              responsive
              bordered
            >
              <b-thead>
                <b-tr id="mm">
                  <b-th>Day</b-th>
                  <b-th>-</b-th>
                  <b-th>Fajr</b-th>
                  <b-th>Sunrise</b-th>
                  <b-th>Dhuhur</b-th>
                  <b-th>Asr</b-th>
                  <b-th>Maghrib</b-th>
                  <b-th>Isha</b-th>
                  <b-th>Action</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(day, index) in prayers"
                  :key="index"
                >
                  <b-td>{{ convertDate(day.day) }}</b-td>
                  <b-td>Azan <br><hr> Eqama</b-td>
                  <b-td
                    v-for="(days, indexs) in prayers"
                    :key="indexs"
                  >
                    <b-form-input
                      ref="days.id"
                      v-model="days.azan_time"
                      disabled
                    />
                    <hr>
                    <b-form-input
                      ref="days.id"
                      v-model="days.eqama_time"
                      :disabled="!toggle"
                    /></b-td>
                  <b-td>
                    <feather-icon
                      v-if="!toggle"
                      icon="EditIcon"
                      @click="toggle = !toggle"
                    />
                    <feather-icon
                      v-if="toggle"
                      icon="SaveIcon"
                      @click="toggle = !toggle"
                    />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BTableSimple, BThead, BTh, BTbody, BTr, BTd, BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'
import UserHeader from './UserHeader.vue'
import prayerNav from './Nav.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCard, BRow, BCol, BButton, BTable, BTableSimple, BThead, BTh, BTbody, BTr, BTd, BFormInput, UserHeader, prayerNav,
  },
  data() {
    return {
      tableColumns: [
        { key: 'day', sortable: true },
        { key: 'fajr', sortable: true },
        { key: 'sunrise', sortable: true },
        { key: 'duhr', sortable: true },
        { key: 'asr', sortable: true },
        { key: 'maghrib', sortable: true },
        { key: 'isha', sortable: true },
        { key: 'actions' },
      ],
      prayersUrl: 'https://stage.muslimdo.com/api/getLandingPageData/',
      ngoId: 4,
      prayers: [],
      toggle: false,
    }
  },
  created() {
    this.getPrayers()
  },
  methods: {
    getPrayers() {
      axios.get(this.prayersUrl + this.ngoId).then(res => {
        this.prayers = res.data.prayers
        console.log(res.data.prayers)
      })
    },
    edit() {
      this.toggle = false
    },
    // eslint-disable-next-line camelcase
    convertDate(date_string) {
      const date = new Date(date_string)
      return `${
        ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]
      }, ${date.getDate()} ${
        [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ][date.getMonth()]
      }`
    },
    convertTime12to24(time12h) {
      const [time, modifier] = time12h.split(' ')
      // eslint-disable-next-line prefer-const
      let [hours, minutes] = time.split(':')
      if (hours === '12') {
        hours = '00'
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12
      }
      return `${hours}:${minutes}`
    },

  },

}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
